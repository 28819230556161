<template>
	<div id="header" class="relative">
		<header class="bg-color-main lg:fixed w-full z-20 sm:py-1">
			<div class="pl-3 pr-5 sm:pl-8">
				<div class="flex items-center h-12">
					<nuxt-link class="cursor-pointer shrink-0" to="/">
						<img
							src="~/assets/svgs/hokify/logo-brand-white.svg"
							alt="hokify"
							class="z-30 max-w-[165px] h-8 relative"
						/>
					</nuxt-link>
					<nav class="hidden sm:flex flex-1 items-center ml-24">
						<nuxt-link
							to="https://hokify.at/"
							active-class="border-color-main-light"
							class="header-link border-transparent"
							@click="saveCountryPreference('at')"
							>{{ locale === 'en' ? 'Austria' : 'Österreich' }}</nuxt-link
						>
						<nuxt-link
							to="https://hokify.de/"
							active-class="border-color-main-light"
							class="header-link border-transparent"
							@click="saveCountryPreference('de')"
							>{{ locale === 'en' ? 'Germany' : 'Deutschland' }}</nuxt-link
						>
						<nuxt-link
							to="https://hokify.ch/"
							active-class="border-color-main-light"
							class="header-link border-transparent"
							@click="saveCountryPreference('ch')"
							>{{ locale === 'en' ? 'Switzerland' : 'Schweiz' }}</nuxt-link
						>
					</nav>
				</div>
			</div>
		</header>
		<div class="hidden lg:block h-12 mb-2"></div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { saveCountryPreference } from '../helpers/preferences';

export default defineComponent({
	name: 'HokHeader',
	data() {
		return {
			// locale: this.$store.state.locale,
			locale: 'at',
			saveCountryPreference
		};
	}
});
</script>

<style scoped lang="scss">
.user-colors {
	fill: #0fb1af;
	stroke: #b3e6e6;
}
</style>
