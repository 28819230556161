<template>
	<main>
		<HokHeader />
		<slot />
		<LazyHokFooter />
	</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HokHeader from '~/components/HokHeader.vue';
import LazyHokFooter from '~/components/HokFooter.vue';

export default defineComponent({
	name: 'DefaultLayout',
	components: {
		HokHeader,
		LazyHokFooter
	}
});
</script>
